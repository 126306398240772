import firebase from 'firebase/app';
import "firebase/analytics";
import { version } from "../../package.json";

export const analyticsMixin = {
  methods: {
    newQuotationLead() {
      const eventParam = {
        eventCategory: 'engagement',
        eventAction: 'click',
        eventLabel: 'Richiesta di preventivo'
      }
      this.fireAnalyticsEvent('generate_lead',eventParam)
      // console.log('quotation event fired')
    },
    newLead() {
      const eventParam = {
        eventCategory: 'engagement',
        eventAction: 'click',
        eventLabel: 'Nuovo lead'
      }
      this.fireAnalyticsEvent('generate_lead',eventParam)
      // console.log('lead event fired')
    },
    fireAnalyticsEvent(event,eventParam) {
      firebase.analytics().logEvent(event, eventParam);
    }
  },
  mounted() {


    if( !window.location.href.includes('localhost')) {

      if(this.$cookies.get('analytics') && this.$metaInfo) {

        const screenData = {
          'app_name': "Gianluca Lomarco Web App",
          'screen_name': this.$metaInfo.title,
          'app_version': version
        }

        // console.log(screenData)

        firebase.analytics().setCurrentScreen(this.$metaInfo.title);
        // firebase.analytics().logEvent("page_view");
        //console.log(this.$metaInfo.title)
        firebase.analytics().logEvent("screen_view", screenData);
      }

      firebase.analytics().logEvent("conversion", {
        'send_to': 'AW-882150572/PsmxCOvVjN8BEKyZ0qQD'
        });
    }
  }
}