<template>
  <section id="contact-form-section" :class="page+'-contact'" class="contact-form-section relative z-100 py-0 lg:py-20">

    <section class="page-content ">
      <h3 class="text-xl lg:text-title font-bold mb-4">Hai un progetto da realizzare?</h3>
      <!--<p class="mb-4"><strong>Non perdere tempo, qualcuno potrebbe avere la tua stessa idea.</strong></p>-->
      <p class="margin-bottom">Lascia un tuo recapito. Ti contatterò appena possibile per discutere del tuo progetto.</p>

      <form @submit.prevent="submit">


        <input
          required
          type="text"
          v-model="name"
          placeholder="Nome"
          class="shadow appearance-none text-black bg-grey placeholder-black text-caption inline rounded-full w-full mb-4 mr-6 py-2 px-4">
        <input
          required
          type="text"
          v-model="phone"
          placeholder="Telefono / email"
          class="shadow appearance-none text-black bg-grey placeholder-black text-caption inline rounded-full w-full mb-8 mr-6 py-2 px-4">

        <vue-recaptcha class="inline" :sitekey="siteKey">
          <button class="inline bg-salmon hover:bg-blue text-white text-button py-2 px-12 mb-8 rounded-full" type="submit">contattami</button>
        </vue-recaptcha>



        <div @click="acceptance = !acceptance" class="cursor-pointer">
          <input required id="acceptance" class="mr-2 leading-tight" v-model="acceptance" type="checkbox">
          <span class="text-sm" for="acceptance">
            Acconsento al trattamento dei dati personali come indicato nella <a class="text-salmon font-bold" target="_blank" href="/privacy-policy.html" title="Vai alla Privacy Policy" v-on:click.stop="">Privacy Policy</a>
          </span>
        </div>

        <div v-if="errors.length" class="mt-4">
          <p v-for="(e,i) in errors" :key="i" class="mb-0 text-orange text-button">{{ e }}</p>
        </div>



      </form>

    </section>

    <svg class="absolute z-0" version="1.1" id="path" x="0px" y="0px"
         width="100%" viewBox="0 0 1943.757 442">

      <path fill="none" :class="page+'-path'" stroke="#000000" stroke-dasharray="14.997,19.996" d="M27.436,157.103
			C216.604,177.6,405.483,399.955,713.103,399.955c322.484,0,541.473-349.356,1051.448-349.356
			c55.163,0,109.255,3.791,161.875,10.548"/>

    </svg>

    <svg :class="page+'-airplane'" height="40" width="40" viewBox="0 0 20 20" fill="currentColor">
      <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
    </svg>

    <modal v-on:closed="fired = false" :regards="true" :fire="fired" :time="6000" :type="type"  :body="mBody"></modal>


  </section>
</template>



<script>
  import { db } from '../db'
  import firebase from 'firebase/app';
  import VueRecaptcha from 'vue-recaptcha';
  import Modal from './Modal'
  import { gsap } from "gsap";
  import { ScrollTrigger } from 'gsap/ScrollTrigger'
  import { MotionPathPlugin } from 'gsap/MotionPathPlugin'
  import { analyticsMixin } from "../mixins/analyticsMixin";

  gsap.registerPlugin(ScrollTrigger);
  gsap.registerPlugin(MotionPathPlugin);


  export default {
    name: "ContactForm",
    mixins: [analyticsMixin],
    components: {
      VueRecaptcha,
      Modal
    },
    props: {
      page: String
    },
    data() {
      return {
        name: '',
        phone: '',
        email: '',
        acceptance: false,
        loading: false,
        siteKey: '6LdeesEZAAAAAKccgXeCYl66BhXyF2_Idjty4BSH',
        fired: false,
        type: 'success',
        mBody: 'Ehi! Ho appena ricevuto il tuo messagio. Ti contatterò il prima possibile.',
        tl : null,
        airplaneUpdate: null,
        scrollTrigger: null,
        errors: []
      }
    },
    methods: {
      resizeWindow() {

        this.alignAirplane(false)

      },
      openPolicy() {
        alert('open modal with privacy policy')
      },
      validate() {
        this.errors = []

        if(!this.acceptance) {
          this.errors.push('Devi accettare le condizioni della Privacy Policy')
        }

        if(!this.name) {
          this.errors.push('Il nome è richiesto.')
        }

        if(!this.phone) {
          this.errors.push('Il tuo contatto è richiesto.')
        }

        if( !(this.phone.match(/[+0-9]+/g) || !this.email.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/) ) ) {
          this.errors.push('Il tuo contatto sembra non essere valido.')
        }
      },
      async submit(e) {

        e.preventDefault();

        this.validate()

        //todo validazione campo email o numero

        if( this.errors.length == 0 ) {

          // clearInterval(this.airplaneUpdate);
          // setTimeout(this.restartInterval,3000)

          this.tl.restart();

          this.loading = true;

          let data;
          let contact;

          if(this.phone) {

            contact = this.phone.replace(' ','').trim();
            data = {
              name: this.name,
              contact: contact,
              type: 'phone',
              timestamp: firebase.firestore.FieldValue.serverTimestamp(),
              privacyAcceptance: this.acceptance
            };

          } else {

            contact = this.email.trim();
            data = {
              name: this.name,
              contact: contact,
              type: 'email',
              timestamp: firebase.firestore.FieldValue.serverTimestamp(),
              privacyAcceptance: this.acceptance
            };
          }

          const refDoc = await db.collection('leads').doc(contact);

          // console.log(doc)

          try {

            await refDoc.get()
              .then((docSnapshot) => {
                if (docSnapshot.exists) {
                  refDoc.update({
                    messages: firebase.firestore.FieldValue.increment(1),
                    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                  });
                } else {
                  refDoc.set({
                    contact: contact,
                    messages: 1,
                    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                  }); // create the document
                }
              });

            // if(doc.id) {
            //   await doc.update({
            //     contact: contact,
            //     messages: firebase.firestore.FieldValue.increment(1)
            //   });
            // } else {
            //   await doc.set({
            //     contact: contact,
            //     messages: 1
            //   });
            // }

            await refDoc.collection('messages').add(data);
            await db.collection('email').add({
              to: ['gianluca.lomarco@gmail.com'],
              from: 'GL web app <web@gianlucalomarco.it>',
              message: {
                subject: 'Richiesta di contatto.',
                text: 'Ciao Gianluca, mi chiamo ' + this.name + ' e vorrei parlarti del mio progetto. Puoi contattarmi qui: ' + contact,
                html: '<p>Ciao Gianluca, mi chiamo ' + this.name + ' e vorrei parlarti del mio progetto.</p><p>Puoi contattarmi qui: '+ contact + '</p>'+
                  '<p><a style="padding: 8px; background-color: #039BE5; color: white; border-radius: 5px; margin-top: 15px; text-decoration: none;" href="https://gianluca-lomarco.web.app/dashboard/leads" target="_blank">Guarda richiesta</a></p>'
              }
            })

            this.newLead();

            this.fired = true
            this.type = 'success'
            // console.log(message);

            this.loading = this.acceptance = false;
            this.email = this.phone = this.name = '';



          } catch(e) {

            this.fired = true
            this.type = 'error'
            this.mBody = 'Sembra che qualcosa sia andato storto. Il tuo messaggio non è stato recapitato. Riprova più tardi'

            //console.log(e);
          }



        }

      },
      alignAirplane(start) {
        // console.log('align');
        gsap.set('.'+this.page+'-airplane',{xPercent: -50,yPercent:-50,transformOrigin:'50% 50%',opacity: 1})
        this.tl = gsap.timeline().to('.'+this.page+'-airplane',{duration:2, motionPath:{
            path: '.'+this.page+'-path',
            autoRotate: 90,
            align: '.'+this.page+'-path',
            immediateRender: true,
            start: 0.5,
            end: 1.5
          }});

        if(!start) {
          this.tl.pause()
          this.tl.progress(0.01);
        }

      },
      iniScrollTrigger() {
        this.alignAirplane(false)

        this.scrollTrigger = ScrollTrigger.create({
          trigger: '.'+this.page+'-contact',
          start: 'center center',
          // markers: true,
          toggleActions: 'play none none none',
          onEnter: () => this.alignAirplane(true),
          onLeave: () => this.alignAirplane(false),
        })
      }

    },
    mounted() {
      setTimeout(this.iniScrollTrigger,500);
    },
    created() {
      // this.scrollTrigger = null
      // this.tl = null;
      gsap.set('.'+this.page+'-airplane',{xPercent: -50,yPercent:-50,transformOrigin:'50% 50%',opacity: 0})
      window.addEventListener("resize", this.resizeWindow);
    },
    beforeDestroy() {
      window.removeEventListener("resize", this.resizeWindow);
      this.scrollTrigger = null
      this.tl = null;
    },

  }
</script>

<style scoped>

  .contact-form-section {
    /*background-image: url("/img/contact-form.png");*/
    /*background-position: center center;*/
    /*background-repeat: no-repeat;*/
    /*background-size: 100%;*/
    /*padding: 90px 0px;*/
  }

  #path {
    bottom: 0px;
    z-index: -100;
  }

  .margin-bottom {
    margin-bottom: 65px;
  }

  input {
    max-width: 300px;
  }

  #airplane {
    opacity: 0;
  }

</style>