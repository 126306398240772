<template>
  <section id="footer" class="page-content md:pb-8">

    <p>
      <img class="logo" src="/img/logo-gianluca-lomarco-sviluppatore-web-designer.svg" alt="Logo Gianluca Lomarco Sviluppatore e Web Designer Freelance" width="250">
    </p>

    <div class="flex flex-wrap justify-between mt-12">
      <p class="text-button font-light mt-3">
        <span class="md:mr-10 mr-2 block md:inline">© Copyright 2020 Gianluca Lomarco</span>
        <span class="whitespace-no-wrap block md:inline md:mr-8 mr-2 mt-3 md:mt-0">P.IVA 03665820365</span>
        <span class="whitespace-no-wrap block md:inline md:mr-8 mr-2 mt-3 md:mt-0"><a href="/privacy-policy.html" title="Vai alla Privacy Policy" target="_blank">Privacy Policy</a></span>
        <span class="whitespace-no-wrap block md:inline md:mr-8 mr-0 mt-3 md:mt-0"><a href="/cookie-policy.html" title="Vai alla Cookie Policy" target="_blank">Cookies Policy</a></span>
      </p>

      <p class="text-button font-light mt-3">
        <a target="_blank" href="https://www.oriens.consulting/" title="Scopri Oriens">
        <span class="md:mr-4 mr-2">
        Partner Consultant
      </span>
          <img class="inline oriens" src="/img/logo_oriens.png" alt="Logo Oriens" width="140">
        </a>
      </p>
    </div>

  </section>
</template>

<script>
  export default {
    name: "Footer"
  }
</script>

<style scoped>

  .oriens {
    width: 120px;
  }

  .logo {
    max-width: 250px;
  }

  p {
    margin-bottom: 0;
  }
</style>